import {Flex, Modal, Space, Table, Tag, Typography} from "antd";
import TextCopy from "../../TextCopy";
import {convertUnixTimestampToDateTime, numberFormat} from "../../../helpers/helpers";
import Search from "antd/es/input/Search";
import {useEffect, useState} from "react";
import SearchInSide from "../../Search/Search";


function BankAccountTransaction({ dataSource, open, setOpen }) {
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(dataSource);

    const items =  [
        {
            name: 'date',
            placeholder: 'Tên đăng nhập',
            type: 'day'
        },
    ]

    const columns = [
        {
            title: 'Mã GD',
            dataIndex: 'transactionId',
            align: 'center',
            width: '200px',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            width: '100px',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Chuyển/Nhận',
            dataIndex: 'io',
            align: 'center',
            width: '150px',
            render: (value) => {
                if (value === 1) {
                    return <Tag color={'success'}>Nhận</Tag>
                }
                return <Tag color={'blue'}>Chuyển</Tag>
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            render: (value) => {
                return <Typography.Paragraph ellipsis={{
                    rows: 1,
                    expandable: true,
                    symbol: 'more',
                }}>{value}</Typography.Paragraph>
            }
        },
        {
            title: 'Đối tượng',
            dataIndex: 'target',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            width: '150px',
            render: (value) => {
                if (value === true) {
                    return <Tag color={'success'}>Thành công</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            align: 'center',
            width: '200px',
            render: (value) => {
                return convertUnixTimestampToDateTime(value);
            }
        }
    ]
    // Hàm xử lý khi người dùng nhập vào ô tìm kiếm
    const handleSearch = (value) => {
        setSearchText(value);
        // Lọc dataSource dựa trên giá trị tìm kiếm
        const filtered = dataSource.filter(item =>
            Object.values(item) // Lấy tất cả các giá trị của đối tượng
                .join(' ') // Gộp các giá trị thành một chuỗi
                .toLowerCase() // Chuyển về chữ thường để tìm kiếm không phân biệt hoa thường
                .includes(value.toLowerCase()) // Kiểm tra xem có chứa giá trị tìm kiếm không
        );
        setFilteredData(filtered); // Cập nhật dữ liệu được lọc
    };

    // Cập nhật filteredData khi dataSource thay đổi
    useEffect(() => {
        setFilteredData(dataSource);
    }, [dataSource]);
    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            width={'80%'}
            title={'Danh sách giao dịch'}
            footer={null}
        >
            <Flex
                justify={'space-between'}
                style={{
                    marginBottom: 30,
                    marginTop: 10
                }}
            >
                <Search
                    size={'middle'}
                    placeholder={'Tìm kiếm'}
                    onSearch={handleSearch}
                    onChange={e => handleSearch(e.target.value)}
                    style={{
                        width: 300
                    }}
                />
            </Flex>
            <Table
                rowKey={'transactionId'}
                columns={columns}
                dataSource={filteredData}
                scroll={{
                    x: 2000,
                }}
                bordered={true}
            />
        </Modal>
    );
}

export default BankAccountTransaction;