import Container from "../components/Container/Container";
import {Breadcrumb, Button, Card, Flex, Form, message, Space, Table, Tag, Typography} from "antd";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import Search from "../components/Search/Search";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import FormDrawer from "../components/FormDrawer/FormDrawer";
import {useContext, useEffect, useState} from "react";
import getBankWithdraw from "../api/withdraw/getBankWithdraw";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../contexts/LoadingContext";
import createWithdrawal from "../api/withdraw/createWithdrawal";
import getWithdrawals from "../api/withdraw/getWithdrawals";
import TextCopy from "../components/TextCopy";
import getWithdrawTransactions from "../api/withdraw-transaction/getWithdrawTransactions";
import dayjs from "dayjs";

function WithdrawTransaction() {
    const [form] = Form.useForm();
    const accessToken = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items = [
        {
            name: 'createdAt',
            type: 'date'
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center'
        },
        {
            title: 'Tài khoản gửi',
            dataIndex: 'bankSender',
            align: 'center',
            render: (_, record) => {
                return (
                    <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
                        <Tag color={'#53c8f3'}>{record.bankNameSender}</Tag>
                        <TextCopy value={record.username}/>
                    </Flex>
                )
            }
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center'
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            align: 'center',
            render: (value) => {
                return (
                    <TextCopy value={value}/>
                )
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accountName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'comment',
            width: '200px',
            align: 'center'
        },
        {
            title: 'Kiểu',
            dataIndex: 'isAuto',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Tự động',
                    value: true
                },
                {
                    text: 'Thủ công',
                    value: false
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Tự động</Tag>
                }
                return <Tag color={'blue'}>Thủ công</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            filters: [
                {
                    text: 'Thành công',
                    value: true
                },
                {
                    text: 'Thất bại',
                    value: false
                },
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Thành công</Tag>
                }
                return <Tag color={'red'}>Thất bại</Tag>
            }
        },
        {
            title: 'Xử lý bởi',
            dataIndex: 'handler',
            align: 'center'
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            render: (value) => {
                return formatDateTime(value)
            }
        }
    ]

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getWithdrawTransactions(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters),
    ])

    return (
        <Container>
            {contextHolder}
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 2000,
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
            />
        </Container>
    )
}

export default WithdrawTransaction;