import Container from "../components/Container/Container";
import {Button,Flex, Form, Image, Input, message, Modal, Radio, Space, Table, Tag} from "antd";
import {useContext, useEffect, useRef, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import getBanks from "../api/bank/getBanks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import { ExportOutlined, ReloadOutlined} from "@ant-design/icons";
import getAuthMethods from "../api/bank/getAuthMethods";
import LoadingContext from "../contexts/LoadingContext";
import auth from "../api/bank-account/auth";
import BankAccountTable from "../components/BankAccount/BankAccountTable/BankAccountTable";

function BankAccount({types = ['wallet']}) {
    const [bankFilters,setBankFilters] = useState([]);
    const accessToken  = useAuthHeader();
    const [open,setOpen] = useState(false);
    const [options,setOptions] = useState([]);
    const [bankName,setBankName] = useState();
    const [step, setStep] = useState('one');
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [icon,setIcon] = useState('');
    const [authMethods,setAuthMethods] = useState([]);
    const [authMethod,setAuthMethod] = useState('CHECK_USERNAME');
    const [username,setUsername] = useState();
    const [params,setParams] = useState({});
    const {setLoading} = useContext(LoadingContext);
    const [loadingLogin, setLoadingLogin] = useState(false);

    const refreshRef = useRef(null);
    const handleStep = () => {
        if (step === 'one') {
            if (!bankName) {
                message.error('Vui lòng chọn ngân hàng!')
                return;
            }
            const fetchData = async () => {
                setLoading(true);
                const response = await getAuthMethods(accessToken,bankName);
                if (response.success) {
                    setAuthMethods(response.data);
                }
                setLoading(false);
            }
            setStep('two');
            fetchData();
        }
        else if (step === 'two') {
            let key = 0;
            authMethods.map((value,index) => {
                if (authMethod === value) {
                    key = index;
                }
            });
            if (authMethod === 'CHECK_USERNAME') {
                const username = form.getFieldValue('username');
                if (!username || username.length <= 0) {
                    message.error('Vui lòng nhập tên tài khoản')
                    return;
                }
                setUsername(username);
                const fetchData = async () => {
                    setLoadingLogin(true);
                    const response = await auth(accessToken, {
                        username,
                        bankName,
                        cmd: 'CHECK_USERNAME'
                    });
                    if (response.success) {
                        if (key < authMethods.length - 1) {
                            setAuthMethod(authMethods[key + 1]);
                        }
                        setParams(response.data);
                    }
                    message.open({
                        type: response.success ? 'success' : 'error',
                        content: response.message
                    });
                    setLoadingLogin(false);
                }
                fetchData();
            }
            else if (authMethod === 'LOGIN_WITH_PASSWORD' || authMethod === 'LOGIN_WITH_OTP') {
                const data =  form.getFieldsValue();
                if (authMethod === 'LOGIN_WITH_PASSWORD') {
                    const password = form.getFieldValue('password');
                    if (!password || password.length <= 0) {
                        message.error('Vui lòng nhập mật khẩu')
                        return;
                    }
                }
                else {
                    const otp = form.getFieldValue('otp');
                    if (!otp || otp.length <= 0) {
                        message.error('Vui lòng nhập mã OTP')
                        return;
                    }
                }
                const fetchData = async () => {
                    setLoadingLogin(true);
                    const response = await auth(accessToken, {
                        ...data,
                        username,
                        bankName,
                        cmd: authMethod,
                        data: params
                    });
                    if (response.success) {
                        if (response.errorCode === 200){
                            if (key < authMethods.length - 1) {
                                setAuthMethod(authMethods[key + 1]);
                            }
                            setParams(response.data);
                        }
                    }
                    message.open({
                        type: response.success ? 'success' : 'error',
                        content: response.message
                    });
                    setLoadingLogin(false);
                }
                fetchData();
            }
        }
    }

    const handleChangeBankName = (e) => {
        setBankName(e.target.value);
        options.map((option) => {
            if (option.value === e.target.value) {
                setIcon(option.icon);
            }
        });
    }

    const handleRefresh = () => {
        if (refreshRef.current) {
            refreshRef.current();
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getBanks(accessToken, types);
            if (response.success) {
                const filters = response.data.map(item => ({
                    text: item.displayName,
                    value: item.bankName
                }));
                const radios = response.data.map((item) => ({
                    icon: item.icon,
                    value: item.bankName,
                    label: <Image src={item.icon} width={50} preview={false}/>,
                    title: item.displayName
                }))
                setBankFilters(filters);
                setOptions(radios);
            }
            setLoading(false);
        }
        fetchData();
    }, [types]);

    return (
        <>
            {contextHolder}
            <Container>
                <Space
                    style={{
                        marginBottom: 16,
                    }}
                >
                    <Button type={'primary'} onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Thêm tài khoản</Button>
                    <Button type={'primary'} icon={<ExportOutlined/>}>Xuất file</Button>
                    <Button type={'default'} onClick={() => handleRefresh()} icon={<ReloadOutlined/>}>Làm mới</Button>
                </Space>
                <BankAccountTable
                    banks={bankFilters}
                    onRefresh={refreshRef}
                    types={types}
                />
            </Container>
            <Modal
                title={'Đăng nhập tài khoản'}
                open={open}
                onCancel={() => setOpen(false)}
                okText={'Tiếp tục'}
                confirmLoading={loadingLogin}
                onOk={handleStep}
            >
                {
                    (() => {
                        if (step === 'one') {
                            return (
                                <Radio.Group onChange={(e) => handleChangeBankName(e)} value={bankName}>
                                    {options.map((option) => (
                                        <Radio key={option.value} value={option.value} title={option.title}>
                                            {option.label}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            )
                        }
                        else if (step === 'two'){
                            let authFields = {
                                'LOGIN_WITH_PASSWORD': { label: 'Nhập mật khẩu', name: 'password' },
                                'LOGIN_WITH_OTP': { label: 'Nhập mã OTP', name: 'otp' }
                            };

                            let { label, name } = authFields[authMethod] || { label: 'Nhập tên tài khoản', name: 'username' };
                            return (
                                <>
                                    <Flex
                                        justify={'center'}
                                        align={'center'}
                                    >
                                        <Image
                                            width={50}
                                            src={icon}
                                        />
                                    </Flex>
                                    <Form
                                        form={form}
                                        layout={'vertical'}
                                        style={{
                                            fontWeight: 600
                                        }}
                                    >
                                        <Form.Item
                                            label={label}
                                            name={name}
                                        >
                                            {name === 'password' ? <Input.Password/> :<Input/>}
                                        </Form.Item>
                                    </Form>
                                </>
                            )
                        }
                    })()
                }
            </Modal>
        </>
    )
}

export default BankAccount;