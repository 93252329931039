import Container from "../../components/Container/Container";
import {Button, Flex, Form, Image, message, Modal, Popconfirm, Space, Table, Tag} from "antd";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useContext, useEffect, useState} from "react";
import {formatDateTime, numberFormat} from "../../helpers/helpers";
import TextCopy from "../../components/TextCopy";
import Search from "../../components/Search/Search";
import getPayOuts from "../../api/admin/pay-out/getPayOuts";
import LoadingContext from "../../contexts/LoadingContext";
import {CheckOutlined, EditOutlined, ReloadOutlined, TransactionOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCancel,
    faCheck,
    faDatabase,
    faImage, faInfo,
    faRepeat,
    faRightToBracket,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import callback from "../../api/admin/pay-out/callback";
import ReadMoreText from "../../components/ReadMoreText";
import getLogs from "../../api/admin/log-pay-out/getLogs";
import cancel from "../../api/admin/pay-out/cancel";
import complete from "../../api/admin/pay-out/complete";
import getBillImage from "../../api/admin/pay-out/getBillImage";
import approve from "../../api/admin/pay-out/approve";
import retryPayOut from "../../api/admin/pay-out/retry";
import getBankAccountTransaction from "../../api/bank-account/getBankAccountTransaction";
import BankAccountTransaction from "../../components/BankAccount/BankTransaction/BankAccountTransaction";
import dayjs from "dayjs";
import searchPartner from "../../api/partner/searchPartner";

function PayOut() {
    const accessToken = useAuthHeader();
    const [messageApi, contextHolder] = message.useMessage();
    const [tableLoading,setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const {setLoading} = useContext(LoadingContext);
    const [recordLoading, setRecordLoading] = useState({});
    const [openModalLog, setOpenModalLog] = useState(false);
    const [logData, setLogData] = useState([]);
    const [openModalImage, setOpenModalImage] = useState(false);
    const [image, setImage] = useState('');
    const [dataTransaction, setDataTransaction] = useState([]);
    const [openModalTransaction, setOpenModalTransaction] = useState(false);
    const [optionQuery,setOptionQuery] = useState([]);
    const [query,setQuery] = useState('');
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0,
        totalFee: 0,
        totalActualAmount: 0,
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items =  [
        {
            name: 'userId',
            type: 'search',
            placeholder: 'Nhập tên đối tác',
            options: optionQuery.map((item) => {
                return {
                    value: item.userId,
                    text: item.name
                }
            }),
            onSearch: (value) => {
                setQuery(value)
            }
        },
        {
            name: 'uuid',
            placeholder: 'Mã đơn',
            type: 'text'
        },
        {
            name: 'requestId',
            placeholder: 'Mã tra soát',
            type: 'text'
        },
        {
            name: 'accNo',
            placeholder: 'Số tài khoản',
            type: 'text'
        },
        {
            name: 'createdAt',
            placeholder: 'Thời gian',
            type: 'date'
        },
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Mã tra soát',
            dataIndex: 'requestId',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Đối tác',
            dataIndex: 'username',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center'
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accNo',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            render: (value) => {
                return numberFormat(value, 2)
            }
        },
        {
            title: 'Tiền trừ',
            dataIndex: 'actualAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            align: 'center'
        },
        {
            title: 'Duyệt',
            dataIndex: 'isApproved',
            align: 'center',
            filters: [
                {
                    text: 'Đã duyệt',
                    value: true
                },
                {
                    text: 'Chờ duyệt',
                    value: false
                }
            ],
            render: (value) => {
                if(value) {
                    return <Tag color={'green'}>Đã duyệt</Tag>
                }
                return <Tag color={'red'}>Chờ duyệt</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            filters: [
                {
                    text: 'Đã hoàn thành',
                    value: true
                },
                {
                    text: 'Chưa hoàn thành',
                    value: false
                }
            ],
            render: (value) => {
                if(value) {
                    return <Tag color={'green'}>Đã hoàn thành</Tag>
                }
                return <Tag color={'red'}>Chưa hoàn thành</Tag>
            }
        },
        {
            title: 'Tình trạng',
            dataIndex: 'statusCode',
            align: 'center',
            render: (value) => {
                if (value === 0) {
                    return <Tag color={'green'}>Thành công</Tag>
                }else if (value === 1) {
                    return <Tag color={'yellow'}>Đang chờ</Tag>
                }else if(value === 2) {
                    return <Tag color={'blue'}>Kiểm tra tên</Tag>
                }else if (value === 3) {
                    return <Tag color={'blue'}>Tạo lệnh</Tag>
                }else if (value === 4) {
                    return <Tag color={'blue'}>Duyệt lệnh</Tag>
                }else if (value === -5) {
                    return <Tag color={'red'}>Tên không khớp</Tag>
                }else if (value === -1) {
                    return <Tag color={'red'}>Kiểm tra lại</Tag>
                }else if (value === -6) {
                    return <Tag color={'red'}>Bị hủy</Tag>
                }
            }
        },
        {
            title: 'Trạng thái CB',
            dataIndex: 'isCallback',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'success'}>Đã CALLBACK</Tag>
                }
                return <Tag color={'yellow'}>Chưa CALLBACK</Tag>
            }
        },
        {
            title: 'Hoàn tiền',
            dataIndex: 'isRefunded',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'success'}>Đã hoàn</Tag>
                }
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            align: 'center',
            sorter: true,
            render: (value)  => {
                return (
                    <Tag>{formatDateTime(value)}</Tag>
                )
            }
        },
        {
            title: 'Thời gian hoàn thành',
            dataIndex: 'completedAt',
            align: 'center',
            sorter: true,
            render: (value)  => {
                if (value) {
                    return (
                        <Tag>{formatDateTime(value)}</Tag>
                    )
                }
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,record) => {
                return (
                    <Space>
                        <Button onClick={() => handleOpenModalLog(record.payOutId)} title={'Log'} icon={<FontAwesomeIcon icon={faDatabase}/>}/>
                        {!record.isApproved && (
                            <Popconfirm
                                title="Duyệt đơn"
                                description="Bạn muốn duyệt đơn xuất khoản này?"
                                onConfirm={() => handleApprovePayOut(record.uuid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={!!recordLoading[record.uuid]} type={'primary'} icon={<FontAwesomeIcon icon={faThumbsUp}/>} title={'Duyệt'}/>
                            </Popconfirm>
                        )}
                        {record.status && (
                            <Popconfirm
                                title="Re callback"
                                description="Bạn muốn gửi lại yêu cầu callback?"
                                onConfirm={() => handleCallbackPayOut(record.uuid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={!!recordLoading[record.uuid]} type={'primary'} icon={<FontAwesomeIcon icon={faRightToBracket}/>} title={'Callback'}/>
                            </Popconfirm>
                        )}
                        {(!record.status && [-1,1].includes(record.statusCode)) && (
                            <Popconfirm
                                title="Hủy giao dịch"
                                description="Bạn muốn hủy giao dịch và hoàn lại tiền cho đối tác?"
                                onConfirm={() => handleCancelPayOut(record.uuid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={!!recordLoading[record.uuid]} danger={true} icon={<FontAwesomeIcon icon={faCancel}/>} title={'Hủy'}/>
                            </Popconfirm>
                        )}
                        {(!record.status && record.statusCode === -1) && (
                            <Popconfirm
                                title="Thành công"
                                description="Bạn muốn xác nhận giao dịch đã thực hiện thành công?"
                                onConfirm={() => handleOkePayOut(record.uuid)}
                                okText="Yes"
                                cancelText="No"
                                icon={<CheckOutlined/>}
                            >
                                <Button loading={!!recordLoading[record.uuid]} type={'primary'} icon={<FontAwesomeIcon icon={faCheck}/>} title={'Thành công'}/>
                            </Popconfirm>
                        )}
                        {(record.status && record.statusCode === 0) && (
                            <Button onClick={() => handleGetBillImage(record.uuid)} loading={!!recordLoading[record.uuid]} type={'primary'} icon={<FontAwesomeIcon icon={faImage}/>} title={'Lấy Bill'}/>
                        )}
                        {(!record.status && record.statusCode === -1) && (
                            <Popconfirm
                                title="Thực hiện lại"
                                description="Bạn muốn thực hiện lại giao dịch này?"
                                onConfirm={() => handleRetryPayOut(record.uuid)}
                                okText="Yes"
                                cancelText="No"
                                icon={<CheckOutlined/>}
                            >
                                <Button loading={!!recordLoading[record.uuid]} type={'primary'} icon={<FontAwesomeIcon icon={faRepeat}/>} title={'Thực hiện lại'}/>
                            </Popconfirm>
                        )}
                    </Space>
                )
            }
        }
    ];

    const logColumns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center',
            width: '240px'
        },
        {
            title: 'Tài khoản',
            dataIndex: 'bankAccount',
            align: 'center',
            width: '200px',
            render: (_, record) => {
                return (
                    <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
                        <Tag color={'#53c8f3'}>{record.bankName}</Tag>
                        <TextCopy value={record.username}/>
                    </Flex>
                )
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            width: '200px',
            render: (value) => {
                if (value) {
                    return <Tag color={'blue'}>Hoàn thành</Tag>
                }
                return <Tag color={'blue'}>Đang thực hiện</Tag>
            }
        },
        {
            title: 'Lỗi',
            dataIndex: 'isError',
            align: 'center',
            width: '200px',
            render: (value) => {
                if (value) {
                    return <Tag color={'red'}>Lỗi</Tag>
                }
            }
        },
        {
            title: 'Tình trạng',
            dataIndex: 'state',
            align: 'center',
            width: '200px',
            render: (value) => {
                if (value === 'validate') {
                    return <Tag color={'yellow'}>Kiểm tra tên</Tag>
                } else if (value === 'transfer') {
                    return <Tag color={'blue'}>Tạo lệnh chuyển</Tag>
                } else if (value === 'wait_otp') {
                    return <Tag color={'blue'}>Xác thực OTP</Tag>
                } else if (value === 'processed') {
                    return <Tag color={'green'}>Hoàn thành</Tag>
                } else if (value === 'wait_approve') {
                    return <Tag color={'blue'}>Chờ duyệt</Tag>
                }
            }
        },
        {
            title: 'Thông tin',
            dataIndex: 'message',
            align: 'center'
        },
        {
            title: 'Chi tiết lỗi',
            dataIndex: 'exception',
            align: 'center',
            render: (value) => {
                if (value){
                    return <ReadMoreText text={value} maxLength={100}/>
                }
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            width: '200px',
            render: (value)  => {
                return (
                    <Tag>{formatDateTime(value)}</Tag>
                )
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,record) => {
                return (
                    <Space>
                        <Button icon={<TransactionOutlined/>} title={'Xem giao dịch'} onClick={() => handleGetBankAccountTransaction(record.bankAccountId)}/>
                    </Space>
                )
            }
        }
    ];

    const handleGetBankAccountTransaction = async (id) => {
        setLoading(true);
        const response = await getBankAccountTransaction(accessToken, id);
        if (response.success) {
            setOpenModalTransaction(true);
            setDataTransaction(response.data);
        }
        setLoading(false);
    }

    const save =  async (uuid, data) => {
        try {
            const newData = [...dataSource];
            const index = newData.findIndex((item) => uuid === item.uuid);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...data,
                });
                setDataSource(newData);
            } else {
                newData.push(data);
                setDataSource(newData);
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
        }
    }

    const handleGetBillImage = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await getBillImage(accessToken, uuid);
        if (response.success) {
            setImage(response.data.image);
            setOpenModalImage(true);
            message.success('Lấy ảnh giao dịch thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleCancelPayOut = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await cancel(accessToken, uuid);
        if (response.success) {
            await save(uuid, response.data);
            message.success('Hủy và hoàn tiền giao dịch thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleOkePayOut = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await complete(accessToken, uuid);
        if (response.success) {
            await save(uuid, response.data);
            message.success('Cập nhật giao dịch thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleRetryPayOut = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await retryPayOut(accessToken, uuid);
        if (response.success) {
            await save(uuid, response.data);
            message.success('Thực hiện lại đơn thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleApprovePayOut = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await approve(accessToken, uuid);
        if (response.success) {
            await save(uuid, response.data);
            message.success('Đã duyệt giao dịch thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleCallbackPayOut = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await callback(accessToken, uuid);
        if (response.success) {
            message.success('Gửi yêu cầu callback thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleSearch = () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    const fetchDataPayOuts = async () => {
        setTableLoading(true);
        const response = await getPayOuts(accessToken, tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount,
                totalFee: response.data.totalFee,
                totalActualAmount: response.data.totalActualAmount,
            })
        }
        setTableLoading(false);
    }

    const handleOpenModalLog = async (payOutId) => {
        setLoading(true);
        const response = await getLogs(accessToken, payOutId);
        if (response.success) {
            setOpenModalLog(true);
            setLogData(response.data.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchDataPayOuts().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters)
    ]);

    useEffect(() => {
        setLoading(true);
        const fetchPartners = async () => {
            const response = await searchPartner(accessToken,query);
            if (response.success) {
                setOptionQuery(response.data)
            }
        }
        fetchPartners().finally(setLoading(false));
    }, [query])

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: '20px'
                }}
            >
                <Button icon={<ReloadOutlined/>} onClick={() => fetchDataPayOuts()}>Làm mới</Button>
            </Space>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                scroll={{
                    x: 2400,
                }}
                bordered={true}
                loading={tableLoading}
                dataSource={dataSource}
                onChange={handleTableChange}
                pagination={tableParams.pagination}
                summary={(data) => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={6}>
                                Thống kê
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalFee)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalActualAmount)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
            <Modal
                title={'Lịch sử pay out'}
                open={openModalLog}
                onCancel={() => setOpenModalLog(false)}
                footer={null}
                width={2200}
            >
                <Table
                    rowKey={'uuid'}
                    columns={logColumns}
                    dataSource={logData}
                    bordered={true}
                    scroll={{
                        x: 2000,
                    }}
                />
            </Modal>
            <Modal
                title={'Ảnh giao dịch chuyển khoản'}
                open={openModalImage}
                onCancel={() => setOpenModalImage(false)}
                footer={null}
            >
                <Image src={image}/>
            </Modal>
            <BankAccountTransaction
                open={openModalTransaction}
                setOpen={setOpenModalTransaction}
                dataSource={dataTransaction}
            />
        </Container>
    )
}

export default PayOut;